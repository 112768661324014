import { CommonModule } from "@angular/common";
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	inject,
} from "@angular/core";
import {
	FormBuilder,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	ValidationErrors,
	Validators,
} from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { setCookie } from "@sportyano/core/common/utils/cookies-controller";
import { AccountService } from "@sportyano/core/services/account/account.service";
import { PlayerService } from "@sportyano/core/services/playerProfile/player.service";
import { ToasterMessagesService } from "@sportyano/core/services/toaster-messages/toaster-messages.service";
import { CheckLanguageDirective } from "@sportyano/shared/directives/check-language/check-language.directive";
import {
	CustomValidationType,
	CustomValidators,
	VariablesValidation,
} from "@sportyano/shared/directives/custom-validation-reactiveForm";

@Component({
	selector: "app-change-mobile",
	templateUrl: "./change-mobile.component.html",
	styleUrl: "./change-mobile.component.scss",
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		CheckLanguageDirective,
		MatTooltipModule,
	],
})
export class ChangeMobileComponent implements OnInit {
	// @ViewChild("phoneInput") set phoneInputRef(ref: ElementRef) {
	// 	if (ref) {
	// 		this.phoneInput = ref;
	// 		// this.initializePhoneNumberInput();
	// 	}
	// }
	private phoneInput!: ElementRef;

	// private iti: any;
	protected changeMobileForm: FormGroup;
	private _fb = inject(FormBuilder);
	public CustomValidationType = CustomValidationType;
	private _translateService = inject(TranslateService);
	private _accountService = inject(AccountService);
	@Input() display: boolean;
	@Input() phoneNumber: string | undefined;
	@Input() isSocial: boolean | false;
	@Output() onEdit: EventEmitter<void> = new EventEmitter<void>();
	@Output() onCancle: EventEmitter<void> = new EventEmitter<void>();
	private _router = inject(Router);
	showPasswordPopup: boolean = false;
	isPasswordSent: boolean = false;
	isPasswordConfirmation: boolean = false;
	resendCooldown: number = 0;
	private resendInterval: any;
	constructor(
		private services: PlayerService,
		private _toaster: ToasterMessagesService,
	) {}
	ngOnInit(): void {
		this.buildChangeMobileForm();
	}

	// private initializePhoneNumberInput() {
	// 	// const inputElement = this.phoneInput.nativeElement;
	// 	// inputElement.addEventListener("keyup", () => {
	// 	// 	this.changeMobileForm.controls["phone_number"].setValue(
	// 	// 		inputElement.value
	// 	// 	);
	// 	// });

	// 	// inputElement.addEventListener("countrychange", () => {
	// 	// 	this.changeMobileForm.controls["phone_number"]
	// 	// });

	// 	// this.iti = intlTelInput(inputElement, {
	// 	// 	allowDropdown: true,
	// 	// 	autoInsertDialCode: true,
	// 	// 	autoPlaceholder: "aggressive",
	// 	// 	formatOnDisplay: true,
	// 	// 	initialCountry: "eg",
	// 	// 	nationalMode: true,
	// 	// 	separateDialCode: true,
	// 	// 	placeholderNumberType: "MOBILE",
	// 	// 	utilsScript: "node_modules/intl-tel-input/build/js/utils.js",
	// 	// });
	// }

	private buildChangeMobileForm() {
		this.changeMobileForm = this._fb.group({
			password: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.minMaxLengthValidation(
						VariablesValidation.password_minLength,
						VariablesValidation.password_maxLength,
					),
				],
			],
			phone_number: [
				"",
				[
					CustomValidators.required(),
					CustomValidators.egyptianMobileNumberValidation(),
				],
			],
		});
	}

	// phoneValidator(): ValidationErrors | null {
	// 	return () => {
	// 		if (!this.phoneInput) {
	// 			return null;
	// 		}
	// 		const isValid = this.iti.isValidNumberPrecise();
	// 		return isValid ? null : { invalidPhone: true };
	// 	};
	// }

	update() {
		// if (!this.changeMobileForm.valid) {
		// 	console.log('invalid');
		// 	return;
		// }
		// setTimeout(() => {
		// 	this._router.navigateByUrl("/main/otp")
		// 		}, 3000);
		let dataToSend = {
			password: this.changeMobileForm.value.password,
			phone_number: this.changeMobileForm.value.phone_number
				.split(" ")
				.join(""),
		};

		this.services.updatePlayerPhone(dataToSend).subscribe({
			next: (res) => {
				setCookie("token_M", res.token);
				this.onCancle.emit();
				this.onEdit.emit();
				if (res.status === 422) {
					this._toaster.showError(
						this._translateService.instant("otp.otp_error"),
					);
				}
				if (res.status === 200) {
					this._router.navigate(["/main/account/account-management/account-info"]);
					this._toaster.showError(
						this._translateService.instant(
							"otp.otp_successfully_redirect",
						),
					);
				}
				setTimeout(() => {
					this._router.navigate(["/auth/otp"]);
				}, 2000);
			},
		});
	}

	getErrorTooltip(controlName: string): string {
		const control = this.changeMobileForm.get(controlName);
		if (control?.invalid && (control?.dirty || control?.touched)) {
			if (
				control?.hasError(CustomValidationType.mobileNumberValidation)
			) {
				return this._translateService.instant(
					"authentication.errors.phoneNumber",
				);
			} else {
				return this._translateService.instant(
					"authentication.errors.phoneNumber",
				);
			}
		}
		return "";
	}

	showTooltip(controlName: string): boolean | null {
		const control = this.changeMobileForm.get(controlName);
		return control && control.invalid && control.dirty && control.touched;
	}

	onCancelEmitter() {
		this.changeMobileForm.reset();
		this.onCancle.emit();
	}
	private startResendCooldown() {
		this.resendCooldown = 60; // Start countdown from 60s

		this.resendInterval = setInterval(() => {
			if (this.resendCooldown > 0) {
				this.resendCooldown--;
			} else {
				clearInterval(this.resendInterval);
			}
		}, 1000);
	}

	resendPassword() {
		if (this.resendCooldown === 0) {
			this.requestPasswordForPhoneChange();
		}
	}

	requestPasswordForPhoneChange() {
		this._accountService.requestPasswordForPhoneChange().subscribe({
			next: (res) => {
				this.isPasswordSent = true;
				if (res.status == "success") {
					this._toaster.showSuccess(res.message);
				} else {
					this._toaster.showError(res.message);
				}
				this.startResendCooldown();
			},
			error: (err) => {
				console.error("Failed to send password:", err);
			},
		});
	}
}
